import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 
    "currentRole", 
    "currentRoleSection", 
    "currentRoleChanged", 
    "currentRoleCheckbox",
    "classificationSelection",
    "classificationDependentField"
  ]

  connect() {
    $(this.classificationSelectionTarget).on('select', (ev) => this.classificationChanged(ev))
    this.showHideExternalUrlField(this.classificationSelectionTarget.value)
  	this.showHideEndDate(this.currentRoleCheckboxTarget.checked)
  }

  currentRoleChanged(event) {
    this.showHideEndDate(event.target.checked)
  }

  showHideEndDate(choice) {
    this.currentRoleSectionTarget.classList.toggle("hidden", choice)
  }

  classificationChanged(event) {
    this.showHideExternalUrlField(event.target.value == "Academic staff" || event.target.value == "Postgraduate research students")
  }

  showHideExternalUrlField(choice) {
    this.classificationDependentFieldTargets.forEach(e => {
      e.classList.toggle('hidden', !choice)
    })
  }
}
